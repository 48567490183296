<script setup lang="ts">
import type { Shop } from '~/store/shops/types';

import ShopsItem from '~/components/Shops/ShopsItem.vue';

interface ShopsListProps {
  isFullList?: boolean
  shops: Shop[]
}

const props = withDefaults(defineProps<ShopsListProps>(), {
  isFullList: false,
  shops: () => [],
});
</script>

<template>
  <ul class='shops-list'>
    <ShopsItem
      v-for='(item, index) in shops'
      v-show='props.isFullList || item.on_main_page'
      :key='item.id'
      :logo='item.image'
      :url='item.url'
      :name='item.name'
      :style="{ transitionDelay: index / 10 + 's' }"
      class='shops-list__item'
    />
  </ul>
</template>

<style scoped lang="scss">
.shops-list {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;

  @media (min-width: $breakpoint-md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ($breakpoint-lg - 1)) {
    border-radius: 1rem;
    overflow: hidden;
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 1rem;

    &--three {
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      gap: 2rem;
    }
  }
}

.shops-list__item {
  transition-property: opacity, transform;
  transition-duration: 0.5s;

  @media (max-width: ($breakpoint-lg - 1)) {
    border: 1px solid #e9f5e3;
  }
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref, useTemplateRef, onUnmounted } from 'vue';
import Button from '~/components/UI/Button.vue';
import { dataLayer } from '~/shared/utils/dataLayer';

const props = defineProps({
  logo: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  isMainPage: {
    type: Boolean,
    default: false,
  },
});

const shop = useTemplateRef('shop');
const observer = ref<IntersectionObserver | null>(null);
const intersected = ref<boolean>(false);
const windowWidth = ref(0);

const getRelNoFollow = computed(() => {
  return props.name.includes('OZON.RU')
    || props.name.includes('Перекресток')
    || props.name.includes('ЯндексМаркет')
    || props.name.includes('Магнит')
    || props.name.includes('Wildberries')
    || props.name.includes('Сбермаркет')
    || props.name.includes('Пятёрочка')
    || props.name.includes('Metro')
    || props.name.includes('Лента')
    || props.name.includes('Petshop')
    || props.name.includes('Любимчик')
    || props.name.includes('Зоозавр')
    || props.name.includes('СберМегаМаркет')
    ? 'nofollow'
    : '';
});

const getImage = () => {
  return intersected.value ? props.logo : '';
};

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

const handleClick = () => {
  if (windowWidth.value < 992) {
    dataLayer('ecom', props.name);
  }
};

onMounted(() => {
  windowWidth.value = window.innerWidth;
  window.addEventListener('resize', handleResize);
  nextTick(() => {
    observer.value = new IntersectionObserver(
      (entries) => {
        const image = entries[0];
        if (image.isIntersecting) {
          intersected.value = true;
          if (observer.value) {
            observer.value.disconnect();
          }
        }
      },
      { threshold: 0 },
    );
    if (shop.value) {
      observer.value.observe(shop.value);
    } else {
      console.error('Observer failed to observe non-existent element in ShopItem');
    }
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
  if (observer.value) {
    observer.value.disconnect();
  }
});
</script>

<template>
  <li
    ref='shop'
    class='shops-item'
    :class="{ 'shops-item__fade-on ': intersected }"
  >
    <a
      :href='url'
      target='_blank'
      class='shop-link'
      :rel='getRelNoFollow'
      @click='handleClick'
    >
      <img
        :src='getImage()'
        loading='lazy'
        :alt='name'
        class='shop-link__image lazy'
      />

      <Button
        :style-type="'olive-border'"
        class='shop-link__button'
        @click="() => dataLayer('ecom', name)"
      >
        Купить
      </Button>
    </a>
  </li>
</template>

<style lang="scss" scoped>
.shops-item {
  display: flex;
  position: relative;
  transition: opacity 0.5s;
  opacity: 0;

  &__fade-on {
    opacity: 1;
  }
}

.shop-link {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: 0.75rem;
  transition: transform 0.2s ease;
  color: var(--color-primary);
  text-decoration: none;

  @media (min-width: $breakpoint-lg) {
    border-radius: 1rem;
    padding: 1.5rem;
  }

  @media (hover: hover) {
    &:hover {
      @media (max-width: ($breakpoint-lg - 1)) {
        .shop-link__image {
          opacity: 0.8;
        }
      }

      @media (min-width: $breakpoint-lg) {
        transform: scale(1.05) translate3d(0, 0, 0);

        .shop-link__button {
          color: var(--red);
          border-color: var(--red);
        }
      }
    }
  }

  &:focus-visible {
    outline: 3px solid color-mix(in srgb, currentColor 50%, transparent);
    outline-offset: -0.25rem;

    .shop-link__button {
      color: var(--red);
      border-color: var(--red);
    }
  }
}

.shop-link__image {
  max-width: 100%;
  height: auto;
  transition: opacity 0.2s ease;
}

.shop-link__button {
  width: 100%;
  align-self: flex-end;
  border-width: 2px;
  font-size: 1.25rem;

  @media (max-width: ($breakpoint-lg - 1)) {
    display: none;
  }
}
</style>
